/**
 * OrderMatrixPage
 */

import { useContext, useEffect, useState } from 'react';
import Layout from 'components/ui/Layout';
import {
	OrderMatrixLandingPageModel,
	OrderMatrixTabPageModel,
} from 'types/page-types';

import {
	OrderMatrixSectionBlockModel,
	OrderMatrixProductBlockModel,
} from 'types/block-types';

import { ProductMatrixRowModel } from 'types/matrix-types';

import { ErrorObjectModel, LinkModel } from 'types/common';

import Breadcrumbs from 'components/framework/Breadcrumbs';
import SectionHeader from 'components/ui/SectionHeader';
import clsx from 'clsx';
import {
	CategoryList,
	CategoryListItem,
} from 'components/framework/CategoryList';
import { Cell, Grid } from 'components/ui/Grid';
import {
	ProductMatrix,
	ProductMatrixRow,
} from 'components/framework/ProductMatrix';
import Button from 'components/ui/Button';
import Icon from 'components/ui/Icon';
import { useDispatch, useSelector } from 'react-redux';
import {
	ChangeTab,
	UpdateActiveTabQuantity,
	selectOrderMatrixData,
	UpdateTotalQuantity,
	selectOrderMatrixObject,
	AddToOrderMatrixCart,
	ClearOrderMatrix,
	AddAllToCart,
} from 'store/modules/ordermatrix';
import { GenericWarning, getURLParameter, ServerError } from 'common/helpers';
import { LocalizationContext } from 'context/localization.context';
import { toast } from 'react-toastify';
import { FetchCart } from 'store/modules/cart';
import AlertModal from 'components/ui/AlertModal';
let articleList: { itemCode: string; source: string; quantity: number }[] = [];

const OrderMatrixPage = ({
	heading,
	text,
	breadcrumbs,
	clearButton,
	exportLinks,
	addAllToCartButton,
}: OrderMatrixLandingPageModel) => {
	const [clearArticlesModalOpen, setClearArticlesModalOpen] = useState(false);

	const { activeTabObj, totalQuantity, activeTabQuantity } = useSelector(
		selectOrderMatrixData
	);
	const {
		cartStatus,
		errorList,
		properties,
		orderMatrixTabPages,
	} = useSelector(selectOrderMatrixObject);

	const dispatch = useDispatch();
	const { t } = useContext<any>(LocalizationContext);

	const handleScrollTo = (e: any) => {
		e.preventDefault();
		const targetId = e.currentTarget?.href.split('#')[1];
		const targetElement = document.getElementById(targetId);

		if (targetElement) {
			window?.scroll({
				top: targetElement.offsetTop,
				behavior: 'smooth',
			});

			window?.history?.pushState(
				{},
				document.title,
				`${window?.location?.pathname}#${targetId}`
			);
		}
	};

	const handleQuantityChange = async (
		itemCode: string,
		source: string,
		previousQuantity: number,
		quantity: number
	) => {
		try {
			const articles = [
				{
					itemCode: itemCode,
					quantity: quantity,
					source: source,
				},
			];
			await dispatch(
				AddToOrderMatrixCart(
					previousQuantity === 0 && quantity > 0 ? 'POST' : 'PATCH',
					articles
				)
			);
		} catch (err) {
			console.log(err);
		}

		const articleIndex = articleList.findIndex(
			(article) => article.itemCode === itemCode && article.source === source
		);

		if (articleIndex > -1 && quantity > 0) {
			articleList[articleIndex].quantity = quantity;
		}
		// Remove article when quantity = 0
		else if (articleIndex > -1 && quantity === 0) {
			articleList.splice(articleIndex, 1);
		} else {
			articleList.push({
				itemCode: itemCode,
				source: source,
				quantity: quantity,
			});
		}

		dispatch(UpdateActiveTabQuantity(articleList));
		dispatch(UpdateTotalQuantity());
	};

	const requestArticleList = () => {
		articleList = [];
		activeTabObj?.orderMatrixSectionBlocks?.forEach(
			(section: OrderMatrixSectionBlockModel) => {
				section.orderMatrixProductBlocks?.forEach(
					(block: OrderMatrixProductBlockModel) => {
						block.articles
							?.filter((x: ProductMatrixRowModel) => x.quantity > 0)
							.forEach((article) => {
								articleList.push({
									itemCode: article.articleNumber,
									source: article.source,
									quantity: article.quantity,
								});
							});
					}
				);
			}
		);
	};

	const handleChangeTab = async (selectedTab: string) => {
		const tabDefault = getURLParameter('tab') || activeTabObj?.id;
		if (selectedTab && selectedTab !== tabDefault) {
			window?.history?.pushState(
				{},
				document.title,
				`${window?.location?.pathname}?tab=${selectedTab}`
			);

			try {
				await dispatch(ChangeTab(window?.location?.pathname, selectedTab));
			} catch (err) {
				console.log(err);
			}
		}
	};

	const handleClearArticles = async () => {
		try {
			await dispatch(
				ClearOrderMatrix(`${clearButton.link}?tab=${activeTabObj?.id}`)
			);
			toast(t('ordermatrixlandingpage/articleshasbeencleared'), {
				type: toast.TYPE.SUCCESS,
			});
		} catch (err) {
			if (err instanceof ServerError) {
				toast(t('shared/toast/servererror'), { type: toast.TYPE.ERROR });
			} else if (err instanceof GenericWarning) {
				toast(err.message, { type: toast.TYPE.WARNING });
			}
		} finally {
			setClearArticlesModalOpen(false);
		}
	};

	const handleAddToCart = async () => {
		try {
			await dispatch(
				AddAllToCart(`${addAllToCartButton.link}?tab=${activeTabObj?.id}`)
			);
			await dispatch(FetchCart());
			toast(t('ordermatrixlandingpage/alladdedtocartmessage'), {
				type: toast.TYPE.SUCCESS,
			});
		} catch (err) {
			if (err instanceof ServerError) {
				toast(t('shared/toast/cartupdateerror'), { type: toast.TYPE.ERROR });
			} else if (err instanceof GenericWarning) {
				toast(err.message, { type: toast.TYPE.WARNING });
			}
		}
	};

	useEffect(() => {
		requestArticleList();
		//eslint-disable-next-line
	}, [orderMatrixTabPages]);

	useEffect(() => {
		if (cartStatus === 'invalid' && errorList && errorList?.length > 0) {
			errorList?.forEach((error: ErrorObjectModel) => {
				const errormsg = `Error ${error.errorCode} - ${error.message}`;
				toast(errormsg, {
					type: toast.TYPE.ERROR,
				});
			});
		}
		//eslint-disable-next-line
	}, [cartStatus]);

	const handleScroll = () => {
		const stickyBar = document?.getElementById('stickyBar');
		const main = document?.getElementById('main-content');

		if (main && stickyBar) {
			if (
				window.pageYOffset + window.innerHeight - stickyBar.offsetHeight <=
				main.offsetHeight + stickyBar.offsetHeight
			) {
				stickyBar.classList.add('sticky');
			} else {
				stickyBar.classList.remove('sticky');
			}
		}
	};
	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<>
			<main id="main-content">
				<Layout backgroundColor="white">
					{breadcrumbs && <Breadcrumbs links={breadcrumbs} className="mb-6" />}
				</Layout>
				<Layout backgroundColor="white">
					<SectionHeader
						heading={heading}
						text={text}
						size={'large'}
						isInEditMode={true}
					/>

					<div>
						<div role="tablist" className="relative calendar-tab-list my-8">
							{orderMatrixTabPages?.map(
								(tab: OrderMatrixTabPageModel, index: number) => {
									return (
										<button
											key={index}
											role="tab"
											id={`tab-${index}`}
											onClick={() => handleChangeTab(tab.id)}
											className={clsx(
												tab.isActive && 'calendar-tab-active',
												'relative py-4 px-5 text-p'
											)}
										>
											{tab.heading}
											<span className="font-bold ml-2">
												{tab.isActive ? activeTabQuantity : tab.quantity}
											</span>
										</button>
									);
								}
							)}
						</div>

						<Grid padding={false}>
							<Cell span={12} desktop={8} className="mb-8">
								<CategoryList className="flex flex-wrap">
									{activeTabObj?.orderMatrixSectionBlocks?.map(
										(
											section: OrderMatrixSectionBlockModel,
											sectionIndex: number
										) => {
											return (
												<CategoryListItem
													key={sectionIndex}
													text={section.heading}
													url={`#section-${sectionIndex}`}
													className="w-full md:w-6/12 lg:w-4/12"
													onClick={handleScrollTo}
												/>
											);
										}
									)}
								</CategoryList>
							</Cell>
						</Grid>
						{activeTabObj?.orderMatrixSectionBlocks?.map(
							(section: OrderMatrixSectionBlockModel, sectionIndex: number) => (
								<Grid padding={false} key={sectionIndex}>
									<Cell
										span={12}
										className={clsx(!section.hideHeading && 'mb-8')}
									>
										<h2
											className={clsx(
												section.hideHeading && 'sr-only',
												'text-h3 text-blue font-semibold'
											)}
											id={`section-${sectionIndex}`}
										>
											{section.heading}
										</h2>
									</Cell>
									{section.orderMatrixProductBlocks?.map(
										(
											block: OrderMatrixProductBlockModel,
											blockIndex: number
										) => (
											<Cell
												span={12}
												tablet={section.layout === 3 ? 4 : 6}
												className="mb-8"
												key={blockIndex}
											>
												<ProductMatrix
													heading={block.heading}
													image={block.image}
													text={block.text}
													helpText={block.helpText}
													properties={properties}
												>
													{block.articles.map(
														(
															item: ProductMatrixRowModel,
															itemIndex: number
														) => {
															return (
																<ProductMatrixRow
																	key={itemIndex}
																	row={item}
																	headers={properties}
																	visibleColumns={block?.display?.columns}
																	onBlur={handleQuantityChange}
																/>
															);
														}
													)}
												</ProductMatrix>
											</Cell>
										)
									)}
								</Grid>
							)
						)}
					</div>
				</Layout>
				{totalQuantity > 0 && (
					<div
						id="stickyBar"
						className={clsx(
							'bg-greyLight py-2 border-t-2 border-greyDark bottom-0 z-50 max-w-360 w-full sticky'
						)}
					>
						<Layout>
							<div className="flex flex-col gap-4 md:gap-0 sm:flex-row justify-between flex-wrap items-center">
								<Button
									type="link"
									buttonColor="orange"
									className="m-0 w-full sm:w-auto"
									url="#main-content"
									onClick={(e) => handleScrollTo(e)}
								>
									<Icon icon="arrow" direction="up" className="ml-0" />
									<span className="ml-2">
										{t('ordermatrixlandingpage/totop')}
									</span>
								</Button>
								{totalQuantity > 0 && (
									<a
										href="#"
										className="text-blue text-p hover:underline"
										onClick={(e) => {
											e.preventDefault();
											setClearArticlesModalOpen(true);
										}}
									>
										{clearButton?.text}
										<Icon icon="close" className="ml-2" />
									</a>
								)}
								{exportLinks?.map((exportLink: LinkModel, index: number) => {
									return (
										<div className="flex justify-end" key={index}>
											<a
												href={exportLink?.link}
												download
												className="text-p text-blue hover:underline"
											>
												<Icon
													className="mr-2"
													icon="download"
													size={2}
													aria-hidden="true"
												/>
												{exportLink?.text}
											</a>
										</div>
									);
								})}
								<p className="text-p">
									<span className="font-bold mr-3">
										{t('ordermatrixlandingpage/total')}
									</span>
									<span className="text-blue text-h3 font-bold mr-3">
										{totalQuantity}
									</span>
									{t('ordermatrixlandingpage/articlesselected')}
								</p>
								{addAllToCartButton && (
									<Button
										buttonColor="green"
										className="m-0 w-full sm:w-auto"
										onClick={handleAddToCart}
									>
										<span className="ml-2">{addAllToCartButton.text}</span>
										<Icon icon="chevrons" size={1.25} direction="left" />
									</Button>
								)}
							</div>
						</Layout>
					</div>
				)}
			</main>
			{clearArticlesModalOpen && (
				<AlertModal
					title={t('ordermatrixlandingpage/cleardialogtitle')}
					heading={t('ordermatrixlandingpage/cleardialogtitle')}
					message={t('ordermatrixlandingpage/cleardialogmessage')}
					cancelButtonText={t('ordermatrixlandingpage/cancelbutton')}
					actionButtonText={t('ordermatrixlandingpage/clearbutton')}
					onToggle={() => setClearArticlesModalOpen(false)}
					onClick={handleClearArticles}
				/>
			)}
		</>
	);
};

export default OrderMatrixPage;
